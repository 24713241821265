"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _glShader = require("gl-shader");

var _glShader2 = _interopRequireDefault(_glShader);

var _glTexture2d = require("gl-texture2d");

var _glTexture2d2 = _interopRequireDefault(_glTexture2d);

var _performanceNow = require("performance-now");

var _performanceNow2 = _interopRequireDefault(_performanceNow);

var _ndarray = require("ndarray");

var _ndarray2 = _interopRequireDefault(_ndarray);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function colorMatches(actual, expected) {
  var dr = actual[0] - expected[0];
  var dg = actual[1] - expected[1];
  var db = actual[2] - expected[2];
  var da = actual[3] - expected[3];
  // we need to be fuzzy because implementation precision can differ
  return dr * dr + dg * dg + db * db + da * da < 10; // euclidian distance < sqrt(10)
}

var pickPositionsForSize = function pickPositionsForSize(w, h) {
  var padX = Math.floor(w / 64);
  var padY = Math.floor(h / 64);
  return [[padX, padY], [w - 1 - padX, padY], [padX, h - 1 - padY], [w - 1 - padX, h - 1 - padY], [Math.floor(w / 2), Math.floor(h / 2)]];
};
var expectedDraw1Picks = [[4, 4, 0, 255], [251, 4, 0, 255], [4, 251, 0, 255], [251, 251, 0, 255], [128, 128, 0, 255]];
var expectedDraw2Picks = [[4, 4, 255, 255], [251, 4, 255, 255], [4, 251, 255, 255], [251, 251, 255, 255], [128, 128, 255, 255]];

var debugColor = function debugColor(c, lbl) {
  var _c = _slicedToArray(c, 4),
      r = _c[0],
      g = _c[1],
      b = _c[2],
      a = _c[3];

  return lbl + ":rgba(" + [r, g, b, a].join(",") + ")";
};

var debugPicks = function debugPicks(picks, tests) {
  return picks.map(function (c, i) {
    return !tests[i] ? debugColor(c, i) : "";
  }).filter(function (f) {
    return f;
  }).join(" ");
};

var VERTEX_SHADER = "attribute vec2 _p;\nvarying vec2 _uv;\nvoid main() {\ngl_Position = vec4(_p,0.0,1.0);\n_uv = vec2(0.5, 0.5) * (_p+vec2(1.0, 1.0));\n}";

exports.default = function (gl) {
  var w = gl.drawingBufferWidth,
      h = gl.drawingBufferHeight;

  var pixels = new Uint8Array(w * h * 4);
  var pickPositions = pickPositionsForSize(w, h);

  function colorAt(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        x = _ref2[0],
        y = _ref2[1];

    var i = (x + y * w) * 4;
    var r = pixels[i + 0];
    var g = pixels[i + 1];
    var b = pixels[i + 2];
    var a = pixels[i + 3];
    return [r, g, b, a];
  }

  var buffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1, -1, -1, 4, 4, -1]), // see a-big-triangle
  gl.STATIC_DRAW);
  gl.viewport(0, 0, w, h);

  var genericTexture = (0, _glTexture2d2.default)(gl, (0, _ndarray2.default)(
  // prettier-ignore
  [0.1, 0.1, 0.1, 1.0, 0.5, 0.5, 0.5, 1.0, 0.5, 0.5, 0.5, 1.0, 0.9, 0.9, 0.9, 1.0], [2, 2, 4]));
  genericTexture.minFilter = gl.LINEAR;
  genericTexture.magFilter = gl.LINEAR;

  return function (_ref3) {
    var glsl = _ref3.glsl,
        paramsTypes = _ref3.paramsTypes,
        defaultParams = _ref3.defaultParams;

    var data = {
      compileTime: 0,
      drawTime: 0
    };
    var errors = [];
    var shader = void 0;
    try {
      var beforeCompilation = (0, _performanceNow2.default)();
      shader = (0, _glShader2.default)(gl, VERTEX_SHADER, "precision highp float;varying vec2 _uv;uniform float progress, ratio;vec4 getFromColor (vec2 uv) { return vec4(uv, 0.0, 1.0); } vec4 getToColor (vec2 uv) { return vec4(uv, 1.0, 1.0); } " + glsl + "\n  void main () {\n    gl_FragColor = transition(_uv);\n  }");
      gl.readPixels(0, 0, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, pixels); // this is just to force trigger a flush
      var afterCompilation = (0, _performanceNow2.default)();
      data.compileTime = afterCompilation - beforeCompilation;

      // We now will check the transition is correctly rendering the {from, to} images in progress={0, 1}
      // leaving all transition params to default zero value should not affect a transition to "work"

      shader.bind();
      shader.attributes._p.pointer();

      for (var key in shader.types.uniforms) {
        if (shader.types.uniforms[key] === "sampler2D") {
          shader.uniforms[key] = genericTexture.bind();
        } else {
          if (key in defaultParams) {
            shader.uniforms[key] = defaultParams[key];
          }
        }
      }

      for (var _key in paramsTypes) {
        if (!(_key in shader.uniforms)) {
          errors.push({
            code: "Transition_unused_uniforms",
            id: _key,
            type: "warn",
            message: "The uniform '" + _key + "' is defined but never used. use it or drop it."
          });
        }
      }

      shader.uniforms.ratio = w / h;

      gl.readPixels(0, 0, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, pixels); // this is just to force trigger a flush
      var beforeDraw1 = (0, _performanceNow2.default)();
      shader.uniforms.progress = 0;
      gl.drawArrays(gl.TRIANGLES, 0, 3);
      gl.readPixels(0, 0, w, h, gl.RGBA, gl.UNSIGNED_BYTE, pixels); // we need to put this in the scope because impl like Chrome are lazy and this really trigger the work.
      var afterDraw1 = (0, _performanceNow2.default)();
      var draw1PixelsPicks = pickPositions.map(colorAt);

      var beforeDraw2 = (0, _performanceNow2.default)();
      shader.uniforms.progress = 1;
      gl.drawArrays(gl.TRIANGLES, 0, 3);
      gl.readPixels(0, 0, w, h, gl.RGBA, gl.UNSIGNED_BYTE, pixels);
      var afterDraw2 = (0, _performanceNow2.default)();
      var draw2PixelsPicks = pickPositions.map(colorAt);

      var drawErrorMessages = [];
      var drawErrorDebug = [];

      var draw1Tests = draw1PixelsPicks.map(function (pick, i) {
        return colorMatches(pick, expectedDraw1Picks[i]);
      });
      if (!draw1Tests.every(function (valid) {
        return valid;
      })) {
        if (draw1PixelsPicks.map(function (pick, i) {
          return colorMatches(pick, expectedDraw2Picks[i]);
        }).every(function (valid) {
          return valid;
        })) {
          drawErrorMessages.push("getFromColor(uv) MUST render when progress=0.0 but NOT getToColor(uv)");
        } else {
          drawErrorMessages.push("getFromColor(uv) MUST render when progress=0.0");
          drawErrorDebug.push(debugPicks(draw1PixelsPicks, draw1Tests));
        }
      }

      var draw2Tests = draw2PixelsPicks.map(function (pick, i) {
        return colorMatches(pick, expectedDraw2Picks[i]);
      });
      if (!draw2Tests.every(function (valid) {
        return valid;
      })) {
        if (draw2PixelsPicks.map(function (pick, i) {
          return colorMatches(pick, expectedDraw1Picks[i]);
        }).every(function (valid) {
          return valid;
        })) {
          drawErrorMessages.push("getToColor(uv) MUST render when progress=1.0 but NOT getFromColor(uv)");
        } else {
          drawErrorMessages.push("getToColor(uv) MUST render when progress=1.0");
          drawErrorDebug.push(debugPicks(draw2PixelsPicks, draw2Tests));
        }
      }

      if (drawErrorMessages.length > 0) {
        errors.push({
          code: "Transition_draw_invalid",
          type: "error",
          message: "invalid transition: " + drawErrorMessages.join(", ") + (drawErrorDebug.length ? " – " + " DEBUG: " + drawErrorDebug.join(" ; ") : "")
        });
      }

      var drawTime1 = afterDraw1 - beforeDraw1;
      var drawTime2 = afterDraw2 - beforeDraw2;
      // average of the 2 draws for even better precision
      data.drawTime = (drawTime1 + drawTime2) / 2;
    } catch (e) {
      var error = void 0;
      var lines = e.message.split("\n");
      for (var _i = 0, _len = lines.length; _i < _len; _i++) {
        var i = lines[_i];
        if (i.substr(0, 5) === "ERROR") {
          error = i;
        }
      }
      if (!error) {
        errors.push({
          line: 0,
          code: "WebGL_unknown_error",
          type: "error",
          message: "Unknown error: " + e.message
        });
      } else {
        var details = error.split(":");
        if (details.length < 4) {
          errors.push({
            line: 0,
            code: "WebGL_error",
            type: "error",
            message: error
          });
        } else {
          var lineStr = details[2];
          var _line = parseInt(lineStr, 10);
          if (isNaN(_line)) _line = 0;
          var _message = details.splice(3).join(":");
          errors.push({
            line: _line,
            code: "WebGL_error",
            type: "error",
            message: _message
          });
        }
      }
    } finally {
      if (shader) shader.dispose();
    }
    return {
      data: data,
      errors: errors
    };
  };
};