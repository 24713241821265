"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function parseValue(s) {
  if (!isNaN(s)) return parseFloat(s, 10);else if (s === "true") return true;else if (s === "false") return false;else return s; // it's probably just a URL! for sampler2D
}
function parse(str) {
  var query = {};
  str.split("&").forEach(function (kv) {
    if (!kv) return;

    var _kv$split = kv.split("="),
        _kv$split2 = _slicedToArray(_kv$split, 2),
        key = _kv$split2[0],
        val = _kv$split2[1];

    if (!key || !val) return;
    val = decodeURIComponent(val);
    var splitByComma = val.split(",");
    query[key] = splitByComma.length > 1 ? splitByComma.map(parseValue) : parseValue(val);
  });
  return query;
}
function stringifyValue(v) {
  return String(v);
}
function stringify(params) {
  return Object.keys(params).map(function (key) {
    return key + "=" + stringifyValue(params[key]);
  }).join("&");
}

exports.default = {
  parseValue: parseValue,
  parse: parse,
  stringifyValue: stringifyValue,
  stringify: stringify
};