"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _glReact = require("gl-react");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

exports.default = (0, _glReact.connectSize)(function (_Component) {
  _inherits(GLTransition, _Component);

  function GLTransition() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, GLTransition);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = GLTransition.__proto__ || Object.getPrototypeOf(GLTransition)).call.apply(_ref, [this].concat(args))), _this), _this.setProgress = function (progress) {
      _this.refs.node.setDrawProps({
        uniforms: _this.getUniformsWithProgress(progress)
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(GLTransition, [{
    key: "getUniformsWithProgress",
    value: function getUniformsWithProgress(progress) {
      var _props = this.props,
          defaultParams = _props.transition.defaultParams,
          transitionParams = _props.transitionParams,
          from = _props.from,
          to = _props.to,
          width = _props.width,
          height = _props.height;

      return _extends({}, defaultParams, transitionParams, {
        progress: progress,
        from: from,
        to: to,
        ratio: width / height
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _props2 = this.props,
          glsl = _props2.transition.glsl,
          progress = _props2.progress;

      return _react2.default.createElement(_glReact.Node, {
        ref: "node",
        shader: {
          frag: "\nprecision highp float;\nvarying vec2 uv;\nuniform float progress, ratio;\nuniform sampler2D from, to;\nvec4 getFromColor(vec2 uv){return texture2D(from, uv);}\nvec4 getToColor(vec2 uv){return texture2D(to, uv);}\n" + glsl + "\nvoid main(){gl_FragColor=transition(uv);}"
        },
        ignoreUnusedUniforms: ["ratio"],
        uniforms: this.getUniformsWithProgress(progress)
      });
    }
  }]);

  return GLTransition;
}(_react.Component));