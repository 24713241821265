export default [
  require("./bilinear-lateral.png"),
  require("./conical-asym.png"),
  require("./conical-sym.png"),
  require("./linear-sawtooth-lateral-4.png"),
  require("./radial-tri-lateral-4.png"),
  require("./spiral-1.png"),
  require("./spiral-2.png"),
  require("./spiral-3.png"),
  require("./square.png"),
];
