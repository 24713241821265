"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typeInfos = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

exports.default = transformSource;

var _string = require("glsl-tokenizer/string");

var _string2 = _interopRequireDefault(_string);

var _direct = require("glsl-parser/direct");

var _direct2 = _interopRequireDefault(_direct);

var _acceptedLicenses = require("./acceptedLicenses");

var _acceptedLicenses2 = _interopRequireDefault(_acceptedLicenses);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function extraPositionFromToken(token) {
  var line = token.line,
      column = token.column;

  var out = {};
  if (typeof line === "number") out.line = line;
  if (typeof column === "number") out.column = column;
  return out;
}

var whitelistMeta = ["author", "license"];

var blacklistScope = ["main", "getToColor", "getFromColor", "ratio", "uv", "progress", "from", "to"];

var reservedTransitionNames = ["new"];

var typeInfos = exports.typeInfos = {
  float: {
    primitiveType: "float",
    exampleValue: "0.7",
    defaultValue: 0,
    arity: 1
  },
  int: {
    primitiveType: "int",
    exampleValue: "42",
    defaultValue: 0,
    arity: 1
  },
  bool: {
    primitiveType: "bool",
    exampleValue: "true",
    defaultValue: false,
    arity: 1
  },
  sampler2D: {
    primitiveType: "sampler2D",
    defaultValue: null,
    arity: 1
  },
  vec2: {
    primitiveType: "float",
    exampleValue: "vec2(1.1, 2.2)",
    defaultValue: Array(2).fill(0),
    arity: 2
  },
  vec3: {
    primitiveType: "float",
    exampleValue: "vec3(1.0, 0.7, 0.3)",
    defaultValue: Array(3).fill(0),
    arity: 3
  },
  vec4: {
    primitiveType: "float",
    exampleValue: "vec4(1.0, 0.7, 0.3, 0.9)",
    defaultValue: Array(4).fill(0),
    arity: 4
  },
  ivec2: {
    primitiveType: "int",
    exampleValue: "ivec2(1, 2)",
    defaultValue: Array(2).fill(0),
    arity: 2
  },
  ivec3: {
    primitiveType: "int",
    exampleValue: "ivec3(1, 2, 3)",
    defaultValue: Array(3).fill(0),
    arity: 3
  },
  ivec4: {
    primitiveType: "int",
    exampleValue: "ivec4(1, 2, 3, 4)",
    defaultValue: Array(4).fill(0),
    arity: 4
  },
  bvec2: {
    primitiveType: "bool",
    exampleValue: "bvec2(true, false)",
    defaultValue: Array(2).fill(false),
    arity: 2
  },
  bvec3: {
    primitiveType: "bool",
    exampleValue: "bvec3(true, false, true)",
    defaultValue: Array(3).fill(false),
    arity: 3
  },
  bvec4: {
    primitiveType: "bool",
    exampleValue: "bvec4(true, false, true, false)",
    defaultValue: Array(4).fill(false),
    arity: 4
  },
  mat2: {
    primitiveType: "float",
    exampleValue: "mat2(1.0)",
    defaultValue: Array(4).fill(0),
    arity: 4
  },
  mat3: {
    primitiveType: "float",
    exampleValue: "mat3(1.0)",
    defaultValue: Array(9).fill(0),
    arity: 9
  },
  mat4: {
    primitiveType: "float",
    exampleValue: "mat4(1.0)",
    defaultValue: Array(16).fill(0),
    arity: 16
  }
};

function extractCommentNode(token) {
  switch (token.type) {
    case "line-comment":
      return token.data.slice(2);
    case "block-comment":
      return token.data.slice(2, token.data.length - 2);
    default:
      return "";
  }
}

function typeCheckTransitionFunction(node) {
  node = node.parent;
  if (node.type !== "function") {
    return false;
  }
  // check return type
  if (node.parent.token.data !== "vec4") {
    return false;
  }
  // back to the function node, we'll check the args
  node = node.children.find(function (n) {
    return n.type === "functionargs";
  });
  if (!node) {
    return false;
  }
  if (node.children.length !== 1) {
    return false;
  }
  node = node.children[0];
  if (node.type !== "decl") {
    return false;
  }
  var args = node.children.filter(function (n) {
    return n.type !== "placeholder";
  });
  if (args.length !== 2) {
    return false;
  }

  var _args = _slicedToArray(args, 2),
      keywordNode = _args[0],
      decllist = _args[1];

  if (keywordNode.type !== "keyword" || keywordNode.token.data !== "vec2") {
    return false;
  }
  if (decllist.type !== "decllist" && decllist.children.length !== 1) {
    return false;
  }

  var _decllist$children = _slicedToArray(decllist.children, 1),
      identNode = _decllist$children[0];

  if (identNode.type !== "ident") {
    return false;
  }
  return true;
}

function transformSource(filename, glsl) {
  var data = {
    name: "",
    paramsTypes: {},
    defaultParams: {},
    glsl: glsl
  };
  var errors = [];

  var tokens = (0, _string2.default)(glsl);

  var ast = void 0;
  try {
    ast = (0, _direct2.default)(tokens);
  } catch (e) {
    var _message = e.message;

    var r = _message.split(" at line ");
    var _line = 0;
    if (r.length === 2) {
      _line = parseInt(r[1], 10);
    }
    errors.push({
      type: "error",
      code: "GLT_GLSL_error",
      message: "GLSL code error: " + e.message,
      line: _line
    });
  }

  if (ast) {
    var forbiddenScopes = Object.keys(ast.scope).filter(function (key) {
      return blacklistScope.includes(key) || key.slice(0, 1) === "_";
    });
    forbiddenScopes.forEach(function (id) {
      // $FlowFixMe
      var token = ast.scope[id].token;
      errors.push(_extends({
        type: "warn",
        code: "GLT_reserved_variable_used",
        id: id,
        message: "'" + id + "' cannot be defined. " + (id.slice(0, 1) === "_" ? "Do not start global scope variables with an underscore. " : "") + "It is reserved for the wrapping GLSL code."
      }, extraPositionFromToken(token)));
    });

    if (!ast.scope.transition) {
      errors.push({
        type: "error",
        code: "GLT_transition_no_impl",
        message: "'vec4 transition(vec2 uv)' function is not implemented"
      });
    } else {
      if (!typeCheckTransitionFunction(ast.scope.transition)) {
        errors.push(_extends({
          type: "error",
          code: "GLT_transition_wrong_type",
          message: "transition must be a function with following signature: 'vec4 transition(vec2 uv)'"
        }, extraPositionFromToken(ast.scope.transition.token)));
      }
    }
  }

  function parseUniformCommentDefault(comment, type, uniformId, uniformToken) {
    comment = comment.trim();
    if (comment.indexOf("=") !== 0) {
      return;
    }
    var tokens = (0, _string2.default)(uniformId + " " + comment + ";");
    // wrap with a more "valid" glsl

    var ast = void 0;
    try {
      ast = (0, _direct2.default)(tokens);
    } catch (e) {
      errors.push(_extends({
        type: "error",
        code: "GLT_invalid_default_value",
        message: "uniform '" + uniformId + "' default value '" + comment + "' does not parse as GLSL code: " + e.message
      }, extraPositionFromToken(uniformToken)));
      return;
    }

    var node = void 0;

    (node = ast) && (node = node.type === "stmtlist" && node.children[0]) && (node = node.type === "stmt" && node.children[0]) && (node = node.type === "expr" && node.children[0]) && (node = node.type === "assign" && node.children[1]);

    var valueNode = node;
    if (!valueNode) {
      errors.push(_extends({
        type: "error",
        code: "GLT_invalid_default_value",
        message: "uniform '" + uniformId + "' has invalid format for default value. Got: '" + comment + "'. It should be an assignment in a comment.\nExample: uniform " + type + " " + uniformId + "; // = " + typeInfos[type].exampleValue
      }, extraPositionFromToken(uniformToken)));
      return;
    }
    var _typeInfos$type = typeInfos[type],
        arity = _typeInfos$type.arity,
        primitiveType = _typeInfos$type.primitiveType;


    function literalToJSValue(node) {
      var literalNode = void 0,
          unary = void 0;
      if (node.type === "unary") {
        literalNode = node.children[0];
        unary = node.data;
      } else {
        literalNode = node;
      }
      switch (primitiveType) {
        case "float":
          {
            var f = parseFloat(literalNode.data, 10);
            if (isNaN(f)) {
              errors.push(_extends({
                type: "error",
                code: "GLT_invalid_default_value",
                message: "uniform '" + uniformId + "' has invalid default value type. Expected a float but could not parseFloat it! Got: '" + literalNode.data + "'"
              }, extraPositionFromToken(uniformToken)));
              return;
            }
            return unary === "-" ? -f : f;
          }
        case "int":
          {
            var i = parseInt(literalNode.data, 10);
            if (isNaN(i)) {
              errors.push(_extends({
                type: "error",
                code: "GLT_invalid_default_value",
                message: "uniform '" + uniformId + "' has invalid default value type. Expected an int but could not parseInt it! Got: '" + literalNode.data + "'"
              }, extraPositionFromToken(uniformToken)));
              return;
            }
            return unary === "-" ? -i : i;
          }
        case "bool":
          {
            switch (literalNode.data) {
              case "1":
              case "true":
                return true;
              case "0":
              case "false":
                return false;
              default:
                errors.push(_extends({
                  type: "error",
                  code: "GLT_invalid_default_value",
                  message: "uniform '" + uniformId + "' has invalid default value type. Expected a bool but could not parse it! Got: '" + literalNode.data + "'"
                }, extraPositionFromToken(uniformToken)));
                return;
            }
          }
        default:
          return;
      }
    }

    if (valueNode.type === "call") {
      var values = [];
      for (var c = 0; c < valueNode.children.length; c++) {
        var _node = valueNode.children[c];
        switch (_node.type) {
          case "keyword":
            if (_node.data !== type) {
              errors.push(_extends({
                type: "error",
                code: "GLT_invalid_default_value",
                message: "uniform '" + uniformId + "' has invalid format for default value: the value type '" + _node.data + "' does not match the uniform type '" + type + "'. Got: '" + comment + "'.\nExample: uniform " + type + " " + uniformId + "; // = " + typeInfos[type].exampleValue
              }, extraPositionFromToken(uniformToken)));
              return;
            }
            break;
          case "unary":
          case "literal":
            {
              var v = literalToJSValue(_node);
              if (v === undefined) return;
              values.push(v);
              break;
            }
          default:
            errors.push(_extends({
              type: "error",
              code: "GLT_invalid_default_value",
              message: "uniform '" + uniformId + "' has invalid format for default value: unsupported syntax. Got: '" + comment + "'.\nExample: uniform " + type + " " + uniformId + "; // = " + typeInfos[type].exampleValue
            }, extraPositionFromToken(uniformToken)));
            return;
        }
      }
      if (arity === values.length) {
        return values;
      }
      if (values.length === 1) {
        return Array(arity).fill(values[0]);
      } else {
        errors.push(_extends({
          type: "error",
          code: "GLT_invalid_default_value",
          message: "uniform '" + uniformId + "' has invalid format for default value: invalid arity of " + type + ". Got: '" + comment + "'.\nExample: uniform " + type + " " + uniformId + "; // = " + typeInfos[type].exampleValue
        }, extraPositionFromToken(uniformToken)));
        return;
      }
    } else if (valueNode.type === "literal" || valueNode.type === "keyword" || valueNode.type === "unary") {
      if (arity !== 1) {
        errors.push(_extends({
          type: "error",
          code: "GLT_invalid_default_value",
          message: "uniform '" + uniformId + "' has invalid format for default value: you can't assign a literal value to a " + type + " type. Got: '" + comment + "'.\nExample: uniform " + type + " " + uniformId + "; // = " + typeInfos[type].exampleValue
        }, extraPositionFromToken(uniformToken)));
      } else {
        return literalToJSValue(valueNode);
      }
    } else {
      errors.push(_extends({
        type: "error",
        code: "GLT_invalid_default_value",
        message: "uniform '" + uniformId + "' has invalid format for default value. Got: '" + comment + "'.\nExample: uniform " + type + " " + uniformId + "; // = " + typeInfos[type].exampleValue
      }, extraPositionFromToken(uniformToken)));
    }
  }

  for (var i = 0; i < tokens.length; i++) {
    var token = tokens[i];
    if (token.type === "keyword" && token.data === "uniform") {
      var _ret = function () {
        var uniformToken = token;
        var idents = [],
            ident = void 0,
            typeTokens = [],
            parsingType = true,
            commentsPerIdent = {},
            commentsAll = [];
        // eat all the tokens until ";"
        while (++i < tokens.length) {
          var _token = tokens[i];
          if (_token.type === "operator" && _token.data === ";") {
            break;
          }
          if (_token.type === "block-comment" || _token.type === "line-comment") {
            if (ident) {
              commentsPerIdent[ident] = (commentsPerIdent[ident] || []).concat([extractCommentNode(_token)]);
            }
            continue;
          }
          if (_token.type === "ident") {
            parsingType = false;
            ident = _token.data;
            idents.push(ident);
            continue;
          }
          if (parsingType && _token.type !== "whitespace") {
            typeTokens.push(_token);
            continue;
          }
        }
        // eat all the comments following the uniform
        for (var j = i + 1; j < tokens.length; j++) {
          var _token2 = tokens[j];
          if (_token2.type === "whitespace") {
            continue;
          }
          if (_token2.type === "block-comment" || _token2.type === "line-comment") {
            commentsAll.push(extractCommentNode(_token2));
          }
          break;
        }

        idents.forEach(function (ident) {
          var type = typeTokens.map(function (n) {
            return n.data;
          }).join("");
          if (ident.slice(0, 1) === "_") {
            errors.push(_extends({
              type: "warn",
              code: "GLT_no_underscore_start",
              message: "uniform '" + ident + "': Do not start parameters with an underscore. Why not naming it '" + ident.replace(/^_+/, "") + "'?"
            }, extraPositionFromToken(uniformToken)));
          }

          if (typeof type !== "string" || !(type in typeInfos)) {
            errors.push(_extends({
              type: "error",
              code: "GLT_unsupported_param_value_type",
              message: "uniform '" + ident + "' type '" + String(type) + "' is not supported"
            }, extraPositionFromToken(uniformToken)));
          } else {
            // Extracting out uniform info
            var defaultParam = void 0;

            if (type === "sampler2D") {
              // for sampler2D, we use null by convention, we can't parse anything.
              defaultParam = null;
            } else {
              // try to parse one of the comment
              var comments = (commentsPerIdent[ident] || []).concat(commentsAll);
              for (var _j = 0; _j < comments.length && defaultParam === undefined; _j++) {
                defaultParam = parseUniformCommentDefault(comments[_j], type, ident, uniformToken);
              }
              // fallback on a default value
              if (defaultParam === undefined) {
                errors.push(_extends({
                  type: "warn",
                  code: "GLT_no_default_param_value",
                  message: "uniform '" + ident + "' has not declared any commented default value.\nExample: uniform " + type + " " + ident + "; // = " + typeInfos[type].exampleValue + ";"
                }, extraPositionFromToken(uniformToken)));
                defaultParam = typeInfos[type].defaultValue;
              }
            }
            data.defaultParams[ident] = defaultParam;
            data.paramsTypes[ident] = type;
          }
        });
        return "continue";
      }();

      if (_ret === "continue") continue;
    }

    if (token.type === "line-comment") {
      // Extracting out meta
      var com = token.data.slice(2);
      var _m = com.match(/^(.*):(.*)$/);
      if (_m && _m.length === 3) {
        var _m2 = _slicedToArray(_m, 3),
            _2 = _m2[0],
            key = _m2[1],
            value = _m2[2];

        key = key.trim().toLowerCase();
        value = value.trim();
        if (!data[key] && whitelistMeta.indexOf(key) !== -1) {
          if (!value) {
            errors.push(_extends({
              type: "error",
              code: "GLT_meta_missing",
              message: "'" + key + "' is empty. Please define a value in '// " + key + ": ...' comment"
            }, extraPositionFromToken(token)));
          } else if (key === "license" && !(value in _acceptedLicenses2.default)) {
            errors.push(_extends({
              type: "error",
              code: "GLT_unknown_license",
              message: "'" + value + "' not found in supported licenses: " + Object.keys(_acceptedLicenses2.default).join(", ")
            }, extraPositionFromToken(token)));
          } else if (key === "author" && value.length > 63) {
            errors.push(_extends({
              type: "error",
              code: "GLT_unknown_license",
              message: "The author field is too long. Got '" + value + "'"
            }, extraPositionFromToken(token)));
          }
          data[key] = value;
        }
      }
      continue;
    }
  }

  whitelistMeta.forEach(function (key) {
    if (!(key in data)) {
      errors.push({
        type: "error",
        code: "GLT_meta_missing",
        message: "'" + key + "' is missing. Please define it in a '// " + key + ": ...' comment"
      });
    }
  });

  var m = filename.match(/^(.*).glsl$/);
  if (m) {
    var _name = m[1];
    data.name = _name;
    if (!_name) {
      errors.push({
        type: "error",
        code: "GLT_invalid_filename",
        message: "A transition filename is required!"
      });
    } else if (_name.length > 40) {
      errors.push({
        type: "error",
        code: "GLT_invalid_filename",
        message: "filename is too long"
      });
    } else if (!_name.match(/^[a-zA-Z0-9-_]+$/)) {
      errors.push({
        type: "error",
        code: "GLT_invalid_filename",
        message: "filename can only contains letters, numbers or - and _ characters. Got '" + filename + "'"
      });
    } else if (reservedTransitionNames.includes(_name)) {
      errors.push({
        type: "error",
        code: "GLT_invalid_filename",
        message: "filename cannot be called '" + _name + "'."
      });
    }
  } else {
    data.name = filename;
    errors.push({
      type: "error",
      code: "GLT_invalid_filename",
      message: "filename needs to ends with '.glsl'. Got '" + filename + "'"
    });
  }

  return {
    data: data,
    errors: errors
  };
}